import React from 'react';

import Layout from '../../components/layout/Layout';

import WafaApp1 from '../../images/elwafaapp1.jpg';
import WafaApp2 from '../../images/elwafaapp2.jpg';
import WafaApp3 from '../../images/elwafaapp3.jpg';

import Ionic from '../../images/ionic.png';
import Onesignal from '../../images/onesignal.png';
import Firebase from '../../images/firebase.png';

export default () => {
  return (
    <Layout pageTitle="El Wafa Client App">
      <h1 className="text-center green-text">El Wafa Client App</h1>
      <p className="text-center">
        A mobile app that uses an API that is part of geimexport.com to let
        clients log in, browse products and more
      </p>
      <hr className="horizontal-ruler" />
      <div className="text-center">
        <img
          src={WafaApp1}
          alt="app 1"
          style={{ maxHeight: 400, marginRight: 30 }}
        />
        <img
          src={WafaApp2}
          alt="app 2"
          style={{ maxHeight: 400, marginRight: 30 }}
        />
        <img src={WafaApp3} alt="app 3" style={{ maxHeight: 400 }} />
      </div>
      <br />
      <p className="text-center">
        <a
          href="https://play.google.com/store/apps/details?id=com.elwafa.testapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          View app <i className="fas fa-external-link-alt" />
        </a>
      </p>
      <h2 className="green-text">Goal</h2>
      <p>
        The aim of this app is to provide El Wafa's existing clients an app that
        allows then to do the following:
      </p>
      <ul className="bullet-points">
        <li>Browse all products with new ones being highlighted.</li>
        <li>
          Find contact info and addresses of the company's different branches.
        </li>
        <li>
          Message the company directly and receive replies inside the app.
        </li>
        <li>Seatch for products.</li>
      </ul>
      <h2 className="green-text">Method</h2>
      <p>
        One of the best ways to expose products/services through an app nowadays
        is through push notifications. So early on, I decided to make that the
        main focus
      </p>
      <p>
        The Home page of the app (after logging in) should contain the most
        important products (the new products and the best selling ones)
      </p>
      <p>
        Through the geimexport.com admin panel, content managers at the company
        could tell the app what the most popular products are and the app
        automatically shows the newest products in the "New products" section
      </p>
      <p>Here are some of the key features of the app</p>
      <ul className="bullet-points">
        <li>Display the latest and most popular products on the home page.</li>
        <li>Notify users of the new products that are available.</li>
        <li>Notify users of private messages sent to them from the company.</li>
        <li>
          Notify users of public announcements (sent to all users subscribed to
          notifications).
        </li>
        <li>
          Allow users to search and find results containing products they're
          inteterested in.
        </li>
        <li>
          Allow users to message the company directly via the app and notify
          them when a response is sent.
        </li>
        <li>
          Notify users when an existing product becomes available to be
          purchased.
        </li>
      </ul>
      <h2 className="green-text">Tech</h2>
      <br />
      <ul className="tech-used">
        <li>
          <a
            href="https://ionicframework.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Ionic} alt="ionic" title="Ionic" />
          </a>
        </li>
        <li>
          <a
            href="https://angular.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://angular.io/assets/images/logos/angular/angular.svg"
              alt="angular"
              title="Angular"
            />
          </a>
        </li>

        <li>
          <a
            href="https://cordova.apache.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://cordova.apache.org/static/img/cordova_bot.png"
              alt="cordova"
              title="Cordova"
            />
          </a>
        </li>

        <li>
          <a
            href="https://onesignal.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Onesignal} alt="onesignal" title="OneSignal" />
          </a>
        </li>
        <li>
          <a
            href="https://firebase.google.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Firebase} alt="firebase" title="Firebase" />
          </a>
        </li>
      </ul>
    </Layout>
  );
};
